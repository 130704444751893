var validationTests = {
	notempty: /^([a-zA-Z0-9\.\s\#\@\-\_\+\,\;\:\'\&\(\)\/\%]{1,})$/,
	string: /^([a-zA-Z]{3,})([a-zA-Z\s]*)$/,
	number: /^([0-9]{1,})$/,
	phone: /^([0-9\-\+\(\)\s]{5,})$/,
	email: /^([0-9a-zA-Z+-_\.]{3,})\@([0-9a-zA-Z+-_]{3,})\.([a-zA-Z]{2,3})([a-zA-Z\.]*)$/,
	zipcode: /^([0-9A-Z]{3,6})(-[0-9]{4})?$/,
	state: /^([A-Z]{2})$/,
	country: /^([A-Z]{2})$/,
	link: /^(http)([:\/\/\.a-zA-Z0-9\-\_\#]{5,})/,
	password: /^([a-zA-Z0-9\s\S]{8,})$/,
};
var validationErrors = {
	name: "<li>Type the name of the Business. Make sure it is at least 3 characters long.</li>",
	firstname: "<li>Type your First Name. Make sure it is at least 3 characters long.</li>",
	lastname: "<li>Type your Last Name. Make sure it is at least 3 characters long.</li>",
	phone: "<li>Type your Phone number. Make sure to add your country code if not in the US.</li>",
	phone2: "<li>Check that you have typed your alternative Phone Number correctly. Make sure to add your country code if not in the US.</li>",
	fax: "<li>Check that you have typed your Fax number correctly. Make sure to add your country code if not in the US.</li>",
	email: "<li>Check that you have typed your Email correctly. Make sure that it contains a username and a domain name.</li>",
	city: "<li>Type the City of your address.</li>",
	state: "<li>Select the State of your address.</li>",
	zipcode: "<li>Type the Zipcode of your address. Make sure it is from 3 to 6 characters long.</li>",
	country: "<li>Select your Country.</li>",
	city_oa: "<li>Type the City of your additional address.</li>",
	state_oa: "<li>Select the State of your additional address.</li>",
	zipcode_oa: "<li>Type the Zipcode of your additional address. Make sure it is from 3 to 6 characters long.</li>",
	country_oa: "<li>Select your Country in your additional address.</li>",
	address_oa: "<li>Type your additional address. Make sure it is at least 3 characters long.</li>",
	link: "<li>Check that the link of the account of your social network is a valid one.</li>",
	password: "<li>Your password must be at least 8 characters long.</li>",
	pass2: "<li>Your password does not match.</li>",
	email_confirm: "<li>The email addresses for one of the new representatives you are trying to add do not match.</li>",
	rep_empty: "<li>All fields are required for new reps</li>"
};
module.exports = function setupUser() {
	return {
		isLogged : function() { return true; },
	}
}
module.exports.addOtherAddress = function addOtherAddress() {
	if ($('#add_other_address').prop('checked')) {
		$('.OtherAddress').removeClass('hidden');
	} else {
		$('.OtherAddress').addClass('hidden');
	}
}
module.exports.validateProfile = function validateProfile(e) {
	var errors = $('<ul>');
	var hasError = false;
	var hasErrorLink = false;
	if (!validationTests.notempty.test($('input[name="FirstName"]').val()) || !validationTests.string.test($('input[name="FirstName"]').val())) {
		errors.append(validationErrors.firstname);
		hasError = true;
		$('input[name="FirstName"]').parent().addClass('has-error');
	}
	if (!validationTests.notempty.test($('input[name="LastName"]').val()) || !validationTests.string.test($('input[name="LastName"]').val())) {
		errors.append(validationErrors.lastname);
		hasError = true;
		$('input[name="LastName"]').parent().addClass('has-error');
	}
	if (!validationTests.phone.test($('input[name="Phone1"]').val())) {
		errors.append(validationErrors.phone);
		hasError = true;
		$('input[name="Phone1"]').parent().addClass('has-error');
	}
	if (validationTests.notempty.test($('input[name="Phone2"]').val())) {
		if (!validationTests.phone.test($('input[name="Phone2"]').val())) {
			errors.append(validationErrors.phone2);
			hasError = true;
			$('input[name="Phone2"]').parent().addClass('has-error');
		}
	}
	if (validationTests.notempty.test($('input[name="Fax"]').val())) {
		if (!validationTests.phone.test($('input[name="Fax"]').val())) {
			errors.append(validationErrors.fax);
			hasError = true;
			$('input[name="Fax"]').parent().addClass('has-error');
		}
	}
	if (!validationTests.notempty.test($('input[name="Email_Address"]').val()) || !validationTests.email.test($('input[name="Email_Address"]').val())) {
		errors.append(validationErrors.email);
		hasError = true;
		$('input[name="Email_Address"]').parent().addClass('has-error');
	}
	if (!validationTests.notempty.test($('input[name="City"]').val())) {
		errors.append(validationErrors.city);
		hasError = true;
		$('input[name="City"]').parent().addClass('has-error');
	}
	if (!validationTests.notempty.test($('select[name="State"]').val()) || !validationTests.state.test($('select[name="State"]').val())) {
		errors.append(validationErrors.state);
		hasError = true;
		$('select[name="State"]').parent().addClass('has-error');
	}
	if (!validationTests.notempty.test($('input[name="Zip"]').val())) {
		errors.append(validationErrors.zipcode);
		hasError = true;
		$('input[name="Zip"]').parent().addClass('has-error');
	}
	if (!validationTests.notempty.test($('input[name="Address1"]').val())) {
		errors.append(validationErrors.address);
		hasError = true;
		$('input[name="Address1"]').parent().addClass('has-error');
	}
	// check additional address only when selected.
	if ($('#add_other_address').prop('checked')) {
		if (!validationTests.notempty.test($('input[name="City_OA"]').val())) {
			errors.append(validationErrors.city_oa);
			hasError = true;
			$('input[name="City_OA"]').parent().addClass('has-error');
		}
		if (!validationTests.notempty.test($('select[name="State_OA"]').val()) || !validationTests.state.test($('select[name="State_OA"]').val())) {
			errors.append(validationErrors.state_oa);
			hasError = true;
			$('select[name="State_OA"]').parent().addClass('has-error');
		}
		if (!validationTests.notempty.test($('input[name="Zip_OA"]').val())) {
			errors.append(validationErrors.zipcode_oa);
			hasError = true;
			$('input[name="Zip_OA"]').parent().addClass('has-error');
		}
		if (!validationTests.notempty.test($('input[name="Address1_OA"]').val())) {
			errors.append(validationErrors.address_oa);
			hasError = true;
			$('input[name="Address1_OA"]').parent().addClass('has-error');
		}
	}
	// now check social links.
	if (validationTests.notempty.test($('input[name="linkedin"]').val())) {
		if (!validationTests.link.test($('input[name="linkedin"]').val())) {
			errors.append(validationErrors.link);
			hasError = true;
			hasErrorLink = true;
			$('input[name="linkedin"]').parent().addClass('has-error');
		}
	}
	if (validationTests.notempty.test($('input[name="twitter"]').val())) {
		if (!validationTests.link.test($('input[name="twitter"]').val())) {
			if (!hasErrorLink) {
				errors.append(validationErrors.link);
			}
			hasError = true;
			hasErrorLink = true;
			$('input[name="twitter"]').parent().addClass('has-error');
		}
	}
	if (validationTests.notempty.test($('input[name="facebook"]').val())) {
		if (!validationTests.link.test($('input[name="facebook"]').val())) {
			if (!hasErrorLink) {
				errors.append(validationErrors.link);
			}
			hasError = true;
			hasErrorLink = true;
			$('input[name="facebook"]').parent().addClass('has-error');
		}
	}
	if (validationTests.notempty.test($('input[name="googleplus"]').val())) {
		if (!validationTests.link.test($('input[name="googleplus"]').val())) {
			if (!hasErrorLink) {
				errors.append(validationErrors.link);
			}
			hasError = true;
			hasErrorLink = true;
			$('input[name="googleplus"]').parent().addClass('has-error');
		}
	}
	if (validationTests.notempty.test($('input[name="youtube"]').val())) {
		if (!validationTests.link.test($('input[name="youtube"]').val())) {
			if (!hasErrorLink) {
				errors.append(validationErrors.link);
			}
			hasError = true;
			hasErrorLink = true;
			$('input[name="youtube"]').parent().addClass('has-error');
		}
	}
	if (validationTests.notempty.test($('input[name="Password"]').val())) {
		if (!validationTests.password.test($('input[name="Password"]').val())) {
			errors.append(validationErrors.password);
			hasError = true;
			$('input[name="Password"]').parent().addClass('has-error');
		}
	}
	if ($('input[name="Password"]').val() != $('input[name="Password2"]').val()) {
		errors.append(validationErrors.pass2);
		hasError = true;
		$('input[name="Password2"]').parent().addClass('has-error');
	}
	if (!hasError && !hasErrorLink) {
		// we can submit
		$('.user-error-content').empty();
		$('#alert-error').hide();
		$('#alert-success').hide();
		$('#alert-danger').hide();
		$('#alert-reset').hide();

		var user = window.Cubic.user.setUserUpdate();
		$('#btn_submit').prop('disabled',true).html('<i class="fa fa-spinner fa-pulse"></i> Please wait ...');
		var subscriptions = []
		$('.email-subscription').each(function() {
			if($(this).data('subscribed') != this.checked) {
            	subscriptions.push({ id: this.name, value: this.checked })
			}
        })

		$.post('/ajax/user-update',{user:user, subscriptions:subscriptions},function(data){
			$('#alert-success').show();
			window.location.href = '#alert-success';
			$('#btn_submit').prop('disabled',false).html('Save');
		}).fail(function(response){
			$('#alert-danger').show();
			window.location.href = '#alert-danger';
			$('#btn_submit').prop('disabled',false).html('Save');
		});
		e.preventDefault();
	} else {
		$('.user-error-content').empty().append(errors);
		$('#alert-error').show();
		window.location.href = '#alert-error';
		e.preventDefault();
	}
	return false;
}
module.exports.setUserUpdate = function setUserUpdate() {
	var user = {
		intMemb_Id: $('input[name="_Memb_Id"]').val(),
		intRep_Id: $('input[name="Rep_Id"]').val(),
		strUserName: $('input[name="Username"]').val(),
		Memb_Id: $('input[name="_Memb_Id"]').val()
	};

	var updateObj = { Email : {}, Phone1 : {}, Phone2 : {}, Fax : {}, MailingAddress : {}, OtherAddress : {} }

	updateObj._Memb_Id = $('input[name="_Memb_Id"]').val()
	updateObj.Rep_Id = $('input[name="Rep_Id"]').val()
	updateObj.Salutation = $('input[name="Salutation"]').val()
	updateObj.FirstName = $('input[name="FirstName"]').val()
	updateObj.MiddleInitial = $('input[name="MiddleInitial"]').val()
	updateObj.LastName = $('input[name="LastName"]').val()
	updateObj.Perferred_Name = $('input[name="Perferred_Name"]').val()
	updateObj.Title = _.escape($('input[name="Title"]').val())
	updateObj.Position = _.escape($('input[name="Position"]').val())
	updateObj.Formal_Greeting = $('input[name="Formal_Greeting"]').val()
	updateObj.Informal_Greeting = $('input[name="Informal_Greeting"]').val()
	updateObj.twitter = _.escape($('input[name="twitter"]').val())
	updateObj.facebook = _.escape($('input[name="facebook"]').val())
	updateObj.linkedin = _.escape($('input[name="linkedin"]').val())
	updateObj.youtube = _.escape($('input[name="youtube"]').val())
	updateObj.googleplus = _.escape($('input[name="googleplus"]').val())
	updateObj.Username = _.escape($('input[name="Username"]').val())
    updateObj.Password = _.escape($('input[name="Password"]').val())
	updateObj.Email_Id = $('input[name="Email_Id"]').val()
	updateObj.MailAddr_Id = $('input[name="MailAddr_Id"]').val()
	updateObj.Phone_Id = $('input[name="Phone1_Id"]').val()
	updateObj.Phone_Id = $('input[name="Phone2_Id"]').val()
	updateObj.Phone_Id = $('input[name="Fax_Id"]').val()
	updateObj.Email.Email_Id = $('input[name="Email_Id"]').val()
	updateObj.Email.Email_Address = $('input[name="Email_Address"]').val()
	updateObj.Phone1.Phone_Id = $('input[name="Phone1_Id"]').val()
	updateObj.Phone1.Phone = $('input[name="Phone1"]').val()
	updateObj.Phone1.Extension = $('input[name="Extension1"]').val()
	updateObj.Phone2.Phone_Id = $('input[name="Phone2_Id"]').val()
	updateObj.Phone2.Phone = $('input[name="Phone2"]').val()
	updateObj.Phone2.Extension = $('input[name="Extension2"]').val()
	updateObj.Fax.Phone_Id = $('input[name="Fax_Id"]').val()
	updateObj.Fax.Phone = $('input[name="Fax"]').val()
	updateObj.MailingAddress.Addr_Id = $('input[name="MailAddr_Id"]').val()
	updateObj.MailingAddress.Address1 = _.escape($('input[name="Address1"]').val())
	updateObj.MailingAddress.Address2 = _.escape($('input[name="Address2"]').val())
	updateObj.MailingAddress.City = $('input[name="City"]').val()
	updateObj.MailingAddress.State = $('select[name="State"]').val()
	updateObj.MailingAddress.Country = $('select[name="Country"]').val()
	updateObj.MailingAddress.Zip = $('input[name="Zip"]').val()
	updateObj.MailingAddress.Memb_Id = $('input[name="_Memb_Id"]').val()
	updateObj.MailingAddress.Rep_Id = $('input[name="Rep_Id"]').val()

	if ($('#add_other_address').prop("checked")) {
		updateObj.OtherAddressEnabled = true
		updateObj.OtherAddress.Addr_Id = $('input[name="OtherAddr_Id"]').val()
		updateObj.OtherAddress.Address1 = $('input[name="Address1_OA"]').val()
		updateObj.OtherAddress.Address2 = $('input[name="Address2_OA"]').val()
		updateObj.OtherAddress.City = $('input[name="City_OA"]').val()
		updateObj.OtherAddress.State = $('select[name="State_OA"]').val()
		updateObj.OtherAddress.Country = $('select[name="Country_OA"]').val()
		updateObj.OtherAddress.Zip = $('input[name="Zip_OA"]').val()
		updateObj.OtherAddress.Memb_Id = $('input[name="_Memb_Id"]').val()
		updateObj.OtherAddress.Rep_Id = $('input[name="Rep_Id"]').val()
	}

	user.update = updateObj
	return user;
}
module.exports.validateBusiness = function validateBusiness(e) {
	var errors = $('<ul>');
	var hasError = false;
	var hasErrorLink = false;
	if (!validationTests.notempty.test($('input[name="Name"]').val())) {
		errors.append(validationErrors.name);
		hasError = true;
		$('input[name="Name"]').parent().addClass('has-error');
	}
	if (!validationTests.phone.test($('input[name="Phone1"]').val())) {
		errors.append(validationErrors.phone);
		hasError = true;
		$('input[name="Phone1"]').parent().addClass('has-error');
	}
	if (!validationTests.notempty.test($('input[name="City"]').val())) {
		errors.append(validationErrors.city);
		hasError = true;
		$('input[name="City"]').parent().addClass('has-error');
	}
	if (!validationTests.notempty.test($('select[name="State"]').val()) || !validationTests.state.test($('select[name="State"]').val())) {
		errors.append(validationErrors.state);
		hasError = true;
		$('select[name="State"]').parent().addClass('has-error');
	}
	if (!validationTests.notempty.test($('input[name="Zip"]').val())) {
		errors.append(validationErrors.zipcode);
		hasError = true;
		$('input[name="Zip"]').parent().addClass('has-error');
	}
	if (!validationTests.notempty.test($('input[name="Address1"]').val())) {
		errors.append(validationErrors.address);
		hasError = true;
		$('input[name="Address1"]').parent().addClass('has-error');
	}
	// check additional address only when selected.
	if ($('#add_other_address').prop('checked')) {
		if (!validationTests.notempty.test($('input[name="City_OA"]').val())) {
			errors.append(validationErrors.city_oa);
			hasError = true;
			$('input[name="City_OA"]').parent().addClass('has-error');
		}
		if (!validationTests.notempty.test($('select[name="State_OA"]').val()) || !validationTests.state.test($('select[name="State_OA"]').val())) {
			errors.append(validationErrors.state_oa);
			hasError = true;
			$('select[name="State_OA"]').parent().addClass('has-error');
		}
		if (!validationTests.notempty.test($('input[name="Zip_OA"]').val())) {
			errors.append(validationErrors.zipcode_oa);
			hasError = true;
			$('input[name="Zip_OA"]').parent().addClass('has-error');
		}
		if (!validationTests.notempty.test($('input[name="Address1_OA"]').val())) {
			errors.append(validationErrors.address_oa);
			hasError = true;
			$('input[name="Address1_OA"]').parent().addClass('has-error');
		}
	}
	// now check social links.
	if (validationTests.notempty.test($('input[name="linkedin"]').val())) {
		if (!validationTests.link.test($('input[name="linkedin"]').val())) {
			errors.append(validationErrors.link);
			hasError = true;
			hasErrorLink = true;
			$('input[name="linkedin"]').parent().addClass('has-error');
		}
	}
	if (validationTests.notempty.test($('input[name="twitter"]').val())) {
		if (!validationTests.link.test($('input[name="twitter"]').val())) {
			if (!hasErrorLink) {
				errors.append(validationErrors.link);
			}
			hasError = true;
			hasErrorLink = true;
			$('input[name="twitter"]').parent().addClass('has-error');
		}
	}
	if (validationTests.notempty.test($('input[name="facebook"]').val())) {
		if (!validationTests.link.test($('input[name="facebook"]').val())) {
			if (!hasErrorLink) {
				errors.append(validationErrors.link);
			}
			hasError = true;
			hasErrorLink = true;
			$('input[name="facebook"]').parent().addClass('has-error');
		}
	}
	if (validationTests.notempty.test($('input[name="googleplus"]').val())) {
		if (!validationTests.link.test($('input[name="googleplus"]').val())) {
			if (!hasErrorLink) {
				errors.append(validationErrors.link);
			}
			hasError = true;
			hasErrorLink = true;
			$('input[name="googleplus"]').parent().addClass('has-error');
		}
	}
	if (validationTests.notempty.test($('input[name="youtube"]').val())) {
		if (!validationTests.link.test($('input[name="youtube"]').val())) {
			if (!hasErrorLink) {
				errors.append(validationErrors.link);
			}
			hasError = true;
			hasErrorLink = true;
			$('input[name="youtube"]').parent().addClass('has-error');
		}
	}
	if($('input[name="rep_email"]').length) {
		if (validationTests.notempty.test($('input[name="rep_email"]').val())) {
			if (!validationTests.email.test($('input[name="rep_email"]').val())) {
				errors.append(validationErrors.email);
				hasError = true;
				$('input[name="rep_email"]').parent().addClass('has-error');
			}
		}
	}
	var $newReps = $('.new-reps .new-rep');
	if($newReps.length > 0) {	
		$.each($newReps, function(index, rep) {
			var fname = $(rep).find('[name="fname"]').val();
			var lname = $(rep).find('[name="lname"]').val();
			var email = $(rep).find('[name="email"]').val();
			var email_confirmation = $(rep).find('[name="email_confirm"]').val();

			if (!validationTests.notempty.test(fname) || !validationTests.notempty.test(lname) || !validationTests.notempty.test(email)) {
				errors.append(validationErrors.rep_empty);
				hasError = true;
				$(rep).addClass('has-error');
			}

			if(email != email_confirmation) {
				errors.append(validationErrors.email_confirm);
				hasError = true;
				$(rep).addClass('has-error');
			}
		});
	}
	if (!hasError && !hasErrorLink) {
		// we can submit
		$('.user-error-content').empty();
		$('#alert-error, #alert-success, #alert-danger, #alert-reset').hide();
		window.Cubic.user.setBusinessUpdate();
		$('#btn_submit').prop('disabled',true).html('<i class="fa fa-spinner fa-pulse"></i> Please wait ...');
		e.preventDefault();
	} else {
		$('.user-error-content').empty().append(errors);
		$('#alert-error').show();
		window.location.href = '#alert-error';
		e.preventDefault();
	}
	return false;
}
module.exports.setBusinessUpdate = function setBusinessUpdate()
{
	$.get('/ajax/business-get', function(data){
		data.Name = _.escape($('input[name="Name"]').val());
		data.DBA = _.escape($('input[name="DBA"]').val());
		data.BusinessDescription = _.escape($('textarea[name="BusinessDescription"]').val());
		data.Phone1.Country_Code = $('input[name="Country_Code"]').val();
		if ($('input[name="Country_Code"]').val().length > 0) data.Phone1.International = true;
		data.Phone1.Phone = $('input[name="Phone1"]').val();
		data.Fax.Phone = $('input[name="Fax"]').val();
		data.Phone2.Phone = $('input[name="Phone2"]').val();
		data.InfoEmail.Email_Address = $('input[name="Email_Address"]').val();
		data.WebSite.Url = _.escape($('input[name="Url"]').val());
		data.MailingAddress.City = $('input[name="City"]').val();
		data.MailingAddress.State = $('select[name="State"]').val();
		data.MailingAddress.Zip = $('input[name="Zip"]').val();
		data.MailingAddress.Address1 = $('input[name="Address1"]').val();
		data.MailingAddress.Address2 = $('input[name="Address2"]').val();
		if ($('select[name="Country"]').val().length > 0) {
			data.MailingAddress.Country = $('select[name="Country"]').val();
			data.MailingAddress.International = true;
		}
		data.dba = _.escape($('input[name="DBA"]').val());
		data.twitter = _.escape($('input[name="twitter"]').val());
		data.facebook = _.escape($('input[name="facebook"]').val());
		data.linkedin = _.escape($('input[name="linkedin"]').val());
		data.youtube = _.escape($('input[name="youtube"]').val());
		data.googleplus = _.escape($('input[name="googleplus"]').val());
		data.addkeyword = _.escape(data.addkeyword);
		data.Custom11 = _.escape(data.Custom11);

		var memberID = $('input[name="_Memb_Id"]').val()

		var repArray = [];
		var $representatives = $('.representative');
		$.each($representatives, function(index, rep) {
			if($(rep).hasClass('mainRep')) return;

			var email = $(rep).find('.rep_email').val();
			var repStatus = $(rep).find('.rep_action:checked').val();
			var initialState = $(rep).find('.repInitialState').val();
			var initialEmail = $(rep).find('.repInitialEmail').val();

			if(repStatus == "active" && !initialState == "active") {
				repStatus = "X";
			}
			else if(repStatus == "inactive" && initialState != "inactive") {
				repStatus = "Y";
			}
			else if(repStatus == "delete") {
				repStatus = "Z";
			}
			else {
				repStatus = false;
			}

			if(repStatus != false || initialEmail != email) {
				repArray.push({
					repId: $(rep).find('.repId').val(),
					membId: $(rep).find('.membId').val(),
					email: $(rep).find('.rep_email').val(),
					status: repStatus
				});
			}
		});

		var newRepsArray = [];
		var $newReps = $('.new-reps .new-rep');
		$.each($newReps, function(index, rep) {
			newRepsArray.push({
				fname: $(rep).find('[name="fname"]').val(),
				lname: $(rep).find('[name="lname"]').val(),
				email: $(rep).find('[name="email"]').val(),
			});
		});

		// sending data to be updated
		$.post('/ajax/business-update',{data:data,reps:repArray, newReps:newRepsArray},function(response){
			if(!response.error) {
				$('.new-reps').empty();
				$('#alert-success').show();
				window.location.href = '#alert-success';
				// Clear cache
				$.post('/members/business/'+memberID+'/clear-cache');
			}
			else {
				$('.user-error-content').empty().append(response.errorMessage);
				$('#alert-error').show();
				window.location.href = '#alert-error';
			}
			$('#btn_submit').prop('disabled',false).html('Save');
		}).fail(function(response){
			$('.user-error-content').empty().append(response.errorMessage);
			$('#alert-danger').show();
			window.location.href = '#alert-danger';
			$('#btn_submit').prop('disabled',false).html('Save');
		});
	});
}

module.exports.addRep = function addRep(e) {
	e.preventDefault();

	var $template = $('.rep-template');
	var $repSection = $('.add-reps-section');
	var $newReps = $('.new-reps');

	$newReps.append($template.clone().html()).show();
	$repSection.slideDown();
}

module.exports.resetChanges = function resetChanges(e) {
	e.preventDefault();

	var choice = confirm("Are you sure you want to cancel your changes?");

	if(choice == true) {
		$('#member-form')[0].reset();
		$('.new-reps').empty();
		$('#alert-reset').show();
		$('html, body').animate({
			scrollTop: $("#alert-reset").offset().top
		}, 800);
	}
}