// Cubic global variable
window.Cubic = window.Cubic || {}

require('./polyfills')
require('./plugins/bootstrap')
require('./components/menu')
require('./plugins/galleria')
require('./plugins/daterangepicker')
require('./plugins/owl-slider')
require('chosen-npm/public/chosen.jquery.min')
require('bootpag/lib/jquery.bootpag.min')
require('jquery.inputmask')
let _ = require('lodash')
var Overlay = require('./plugins/overlay')
var Masonry = require('masonry-layout')
var committee = require('./committee')
var moment = require('moment')
var business = require('./memberdirectory')
var products = require('./products')
var user = require('./user')
var cart = require('./cart')
var search = require('./search')
var labs = require('./labs')
var cpd = require('./cpd')
var ams = require('./ams')
var helpers = require('./plugins/helpers')

// Hook up internal components to the Cubic global object
_.extend(window.Cubic, {
	ams: ams,
	cart : cart,
	user : user,
	committee: committee,
	moment : moment,
	search : search,
	business : business,
	labs : labs,
	helpers: helpers,
	cpd: cpd,
	Masonry : Masonry,
	Overlay : Overlay,
	showLoginBox : function() {
		$('.navbox-login').show();
		$('.navbox-links').hide();
	},
})

// Smooth scroll
$('a[href*=#]:not([href=#]):not([data-toggle])').on('click', function() {
	if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
	  var target = $(this.hash);
	  target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
	  if (target.length) {
	    $('html,body').animate({
	      scrollTop: target.offset().top
	    }, 1000);
	    return false;
	  }
	}
});

$('.new-products .box').map(function(index,el) { let a = $(el).height(); let height = (a > height) ? a : height; })

window.savePaymentForm = function (response) {
	console.log("Saving payment form!");

	if (response.messages.resultCode === "Error") {
		if (response.messages.message !== "undefined" && response.messages.message.length) {
			response.messages.message.forEach((message) => {
				console.log(message.code + ": " + message.text);
				validationError(message);
			})
		}
	}
	else {
		$("#dataDescriptor").val(response.opaqueData.dataDescriptor);
		$("#dataValue").val(response.opaqueData.dataValue);
		validationSuccess("Payment information successfully saved!");
   	}
}

function validationError(error) {
	var template = `<div class="error">
						<div class="alert alert-danger alert-dismissible" role="alert">
							<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
							${error.code}: ${error.text}
						</div>
					</div>`;

	$("#validationErrors").append(template);
}

function validationSuccess(message) {
	var template = `<div class="notice">
						<div class="alert alert-success alert-dismissible" role="alert">
							<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
							${message}
						</div>
					</div>`;

	$("#validationErrors").append(template);
}
