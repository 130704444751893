jQuery(function($) {
	$( document ).ready(function() {
		$('.owl-slider').owlCarousel({
			items : 1,
			loop: true,
			autoHeight: true,
			autoplay: true,
			autoplayHoverPause: true,
		});
	});
});