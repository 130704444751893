module.exports.getManufacturers = getManufacturers
module.exports.getManufacturerPlants = getManufacturerPlants
module.exports.getOperatorTypes = getOperatorTypes
module.exports.getPerformanceClasses = getPerformanceClasses
module.exports.getPerformanceGrades = getPerformanceGrades
module.exports.getFramingMaterials = getFramingMaterials
module.exports.getStandards = getStandards
module.exports.search = search
module.exports.reset = reset
module.exports.searchByCPDNumber = searchByCPDNumber
module.exports.searchByManufacturerCode = searchByManufacturerCode
module.exports.previousPage = previousPage
module.exports.nextPage = nextPage
module.exports.isParamSet = loadParam
module.exports.setPage = setPage

function getManufacturers() {
	let $manufacturerDropdown = $('#manufacturer');

	$.ajax({
        type: 'GET',
        url: '/ajax/get-manufacturers/',
        success: function(data, textStatus, jqXHR) {

			if(!data.error) {
				data.forEach( ( manufacturer ) => {
					$manufacturerDropdown.append('<option value="' + manufacturer.ManufacturerId + '">' + manufacturer.CompanyName +'</option>');
				})

				let selected = loadParam('manufacturer');
				if(selected) $manufacturerDropdown.val(selected);

				$(".chosen-select").trigger('chosen:updated');
			}
			else {
				console.log("ERROR: Failed to retrieve manufacturers!");
			}
        }
    });
}

function getManufacturerPlants() {
	let $manufacturerCodeDropdown = $('#manufacturerCode');

	$.ajax({
        type: 'GET',
        url: '/ajax/get-manufacturer-plants/',
        success: function(data, textStatus, jqXHR) {

			if(!data.error) {
				data.forEach( ( manufacturerPlant ) => {
					$manufacturerCodeDropdown.append('<option value="' + manufacturerPlant.Code + '">' + manufacturerPlant.Code +'</option>');
				})
				$(".chosen-select").trigger('chosen:updated');
			}
			else {
				console.log("ERROR: Failed to retrieve manufacturer plants!");
			}
        }
    });
}

function getOperatorTypes() {
	let $operatorTypeDropdown = $('#operatorType');

	$.ajax({
        type: 'GET',
        url: '/ajax/get-operator-types/',
        success: function(data, textStatus, jqXHR) {

			if(!data.error) {
				data.forEach( ( operatorType ) => {
					$operatorTypeDropdown.append('<option value="' + operatorType.Code + '">' + operatorType.Code +'</option>');
				})

				let selected = loadParam('operatorType');
				if(selected) $operatorTypeDropdown.val(selected);

				$(".chosen-select").trigger('chosen:updated');
			}
			else {
				console.log("ERROR: Failed to retrieve operator types!");
			}
        }
    });
}

function getPerformanceClasses() {
	let $performanceClassDropdown = $('#performanceClass');

	$.ajax({
        type: 'GET',
        url: '/ajax/get-performance-classes/',
        success: function(data, textStatus, jqXHR) {

			if(!data.error) {
				data.forEach( ( performanceClass ) => {
					$performanceClassDropdown.append('<option value="' + performanceClass.Code + '">' + performanceClass.Code +'</option>');
				})

				let selected = loadParam('performanceClass');
				if(selected) $performanceClassDropdown.val(selected);

				$(".chosen-select").trigger('chosen:updated');
			}
			else {
				console.log("ERROR: Failed to retrieve performance classes!");
			}
        }
    });
}

function getPerformanceGrades() {
	let $performanceGradeDropdown = $('#performanceGrade');
	let $performanceGradeOperator = $('#performanceGradeOperator');

	$.ajax({
        type: 'GET',
        url: '/ajax/get-performance-grades/',
        success: function(data, textStatus, jqXHR) {

			if(!data.error) {
				data.forEach( ( performanceGrade ) => {
					$performanceGradeDropdown.append('<option value="' + performanceGrade+ '">' + performanceGrade +'</option>');
				})

				let selectedGrade = loadParam('performanceGrade');
				let selectedGradeOperator = loadParam('performanceGradeOperator');
				if(selectedGrade) $performanceGradeDropdown.val(selectedGrade);
				if(selectedGradeOperator) $performanceGradeOperator.val(selectedGradeOperator);

				$(".chosen-select").trigger('chosen:updated');
			}
			else {
				console.log("ERROR: Failed to retrieve performance grades!");
			}
        }
    });
}

function getFramingMaterials() {
	let $frameMaterialDropdown = $('#frameMaterial');

	$.ajax({
        type: 'GET',
        url: '/ajax/get-frame-materials/',
        success: function(data, textStatus, jqXHR) {

			if(!data.error) {
				data.forEach( ( material ) => {
					$frameMaterialDropdown.append('<option value="' + material.Code + '">' + material.Code +'</option>');
				})

				let selected = loadParam('frameMaterial');
				if(selected) $frameMaterialDropdown.val(selected);

				$(".chosen-select").trigger('chosen:updated');
			}
			else {
				console.log("ERROR: Failed to retrieve frame materials!");
			}
        }
    });
}

function getStandards() {
	let $standardsDropdown = $('#specification');

	$.ajax({
        type: 'GET',
        url: '/ajax/get-standards/',
        success: function(data, textStatus, jqXHR) {

			if(!data.error) {
				data.forEach( ( standard ) => {
					$standardsDropdown.append('<option value="' + standard.StandardId + '">' + standard.Description +'</option>');
				})

				let selected = loadParam('standards');
				if(selected) $standardsDropdown.val(selected.split(','));

				$(".chosen-select").trigger('chosen:updated');
			}
			else {
				console.log("ERROR: Failed to retrieve standards!");
			}
        }
    });
}

function search(loadState, newSearch) {
	let $searchForm = $('#cpdSearchForm');
	let $searchResultsBox = $('.search-results-box');
	let $resultsTable = $('#searchResults');
	let $totalRecords = $('#totalRecords');
	let $noResults = $('#noResults');
	let page = (loadState) ? loadParam('page') : parseInt($searchForm.find('#currentPage').val()) + 1;

	if(newSearch) {
		page = 1;
		$searchForm.find('#currentPage').val(page - 1);
	}

	$totalRecords.text(0);
	$noResults.slideUp();
	$searchResultsBox.slideUp();
	$resultsTable.find('tbody').empty();

	$.ajax({
        type: 'GET',
        url: '/ajax/search-cpd/',
        data: {
        	page: page - 1,
			manufacturer: (loadState) ? loadParam('manufacturer') : $searchForm.find('#manufacturer').val(),
			operatorType: (loadState) ? loadParam('operatorType') : $searchForm.find('#operatorType').val(),
			performanceClass: (loadState) ? loadParam('performanceClass') : $searchForm.find('#performanceClass').val(),
			performanceGrade: (loadState) ? loadParam('performanceGrade') : $searchForm.find('#performanceGrade').val(),
			performanceGradeOperator: (loadState) ? loadParam('performanceGradeOperator') : $searchForm.find('#performanceGradeOperator').val(),
			frameMaterial: (loadState) ? loadParam('frameMaterial') : $searchForm.find('#frameMaterial').val(),
			standards: (loadState) ? loadParam('standards') : $searchForm.find('#specification').val(),
        },
        success: function(data, textStatus, jqXHR) {

			if(!data.error) {

				let count = data.count;
				let products = data.data;
				let perPage = data.perPage;

				let totalPages = Math.floor(count / perPage) + 1;

				products.forEach( ( product ) => {
					let $row = $('<tr>');

					let standards = product.Standards.reduce( (memo, standard, index) => {
						let standardString = memo + standard.Name;
						standardString += (index < product.Standards.length - 1) ? ", " : "";

						return standardString
					}, "");

					$row.append('<td><a href="/cpd/product/'+product.CpdNumber+'">' + cleanOutput(product.CpdNumber) +'</a></td>');
					$row.append('<td><a href="/cpd/manufacturer/'+product.ManufacturerPlantCode+'">' + cleanOutput(product.Manufacturer) +'</a></td>');
					$row.append('<td>' + cleanOutput(product.ManufacturerCode) +'</td>');
					$row.append('<td>' + cleanOutput(product.SeriesModelNumber) +'</td>');
					$row.append('<td>' + cleanOutput(product.OperatorTypeCode) +'</td>');
					$row.append('<td>' + getRating(product) + '</td>');
					$row.append('<td>' + standards +'</td>');

					$resultsTable.append($row);
				})

				// Set URL history
				if(!loadState) {
					setHistoryState();
				}

				// Set pagination data
				$totalRecords.text(count);
				$('.page').text(page);
				$('.total-pages').text(totalPages);
				$('#lastPage').val(totalPages);

				if(totalPages > 2) {
					$('.page').html('<select id="pageSelector" class="page-selector"></select>');

					for(let i = 1; i <= totalPages; i++) {
						$('#pageSelector').append('<option value="' + i + '">' + i +'</option>');
					}

					$('#pageSelector').val(page);

					$('#pageSelector').on('change', () => {
						gotoPage($('#pageSelector').val());
					});
				}

				if(page == 0 || page == 1) {
					$('.search-nav .prev-page').slideUp();
				}
				else {
					$('.search-nav .prev-page').slideDown();
				}

				if(page == totalPages) {
					$('.search-nav .next-page').slideUp();
				}
				else {
					$('.search-nav .next-page').slideDown();
				}

				$searchResultsBox.slideDown();

				if(count) {
					$resultsTable.slideDown();
				}
				else {
					$resultsTable.slideUp();
					$noResults.slideDown();
				}

				scrollToResults();

			}
			else {
				console.log("ERROR: Failed to retrieve search!");
			}
        }
    });
}

function reset() {
	let $searchForm = $('#cpdSearchForm');
	let $cpdNumberForm = $('#cpdNumberForm');

	$searchForm.trigger('reset');
	$cpdNumberForm.trigger('reset');
	$(".chosen-select").trigger('chosen:updated');

	clearHistoryState();
}

function cleanOutput(output) {
	if(output == null) {
		output = ""
	}

	return output
}

function getRating(product) {
	let rating = ""

	if(product.PerformanceClassCode && product.PerformanceGrade && product.Standards.length) {
		let standard = product.Standards.find( (standard) => { return standard.Name.indexOf('NAFS') != -1 } )

		if(!standard) standard = product.Standards[0];

		if(standard.MaxSizeTesteds.length) {
			let frameSizeObject = standard.MaxSizeTesteds.find( (size) => { return size.Type == "Frame" } );

			if(frameSizeObject) {
				let sizeString = frameSizeObject.IpWidth + " x " + frameSizeObject.IpHeight;

				rating = product.PerformanceClassCode + "-" + product.PerformanceGrade + "-" + sizeString;
			}
		}
	}

	return rating;
}

function setHistoryState() {
	let url = new URL(window.location);
	let $searchForm = $('#cpdSearchForm');
	let page = parseInt($searchForm.find('#currentPage').val()) + 1;

	let manufacturer = $searchForm.find('#manufacturer').val();
	let operatorType = $searchForm.find('#operatorType').val();
	let performanceClass = $searchForm.find('#performanceClass').val();
	let performanceGrade = $searchForm.find('#performanceGrade').val();
	let performanceGradeOperator = $searchForm.find('#performanceGradeOperator').val();
	let frameMaterial = $searchForm.find('#frameMaterial').val();
	let standards = $searchForm.find('#specification').val();

	url.searchParams.set('page', page);
	url.searchParams.set('manufacturer', manufacturer);
	url.searchParams.set('operatorType', operatorType);
	url.searchParams.set('performanceClass', performanceClass);
	url.searchParams.set('performanceGrade', performanceGrade);
	url.searchParams.set('performanceGradeOperator', performanceGradeOperator);
	url.searchParams.set('frameMaterial', frameMaterial);
	url.searchParams.set('standards', standards);

	window.history.pushState({}, '', url);
}

function clearHistoryState() {
	let url = new URL(window.location);

	url.searchParams.set('page', "");
	url.searchParams.set('manufacturer', "");
	url.searchParams.set('operatorType', "");
	url.searchParams.set('performanceClass', "");
	url.searchParams.set('performanceGrade', "");
	url.searchParams.set('performanceGradeOperator', "");
	url.searchParams.set('frameMaterial', "");
	url.searchParams.set('standards', "");

	window.history.pushState({}, '', url);
}

function loadParam(paramKey) {
	let url = new URL(window.location);
	let paramValue = url.searchParams.get(paramKey);

	return (paramValue) ? paramValue : "";
}

function searchByCPDNumber() {
	let cpdNumber = $('#cpdNumber').val();

	if(!cpdNumber) return;

	window.location.href= "/cpd/product/" + cpdNumber;
}

function searchByManufacturerCode() {
	let manufacturerCode = $('#manufacturerCode').val();

	if(!manufacturerCode) return;

	window.location.href= "/cpd/manufacturer/" + manufacturerCode;
}

function scrollToResults() {
	let $searchResultsBox = $('.search-results-box');

	$('html, body').animate({
		scrollTop: $searchResultsBox.offset().top
    }, 1000);
}

function previousPage() {
	let $searchForm = $('#cpdSearchForm');
	let $page = $searchForm.find('#currentPage');
	let currentPage = parseInt($page.val());

	if(currentPage != 0) {
		$page.val(currentPage - 1);
		search();
	}
}

function nextPage() {
	let $searchForm = $('#cpdSearchForm');
	let $page = $searchForm.find('#currentPage');
	let $lastPage = $searchForm.find('#lastPage');
	let currentPage = parseInt($page.val());
	let lastPage = parseInt($lastPage.val());

	if(currentPage + 1 <= lastPage) {
		$page.val(currentPage + 1);
		search();
	}
}

function gotoPage(page) {
	let $searchForm = $('#cpdSearchForm');
	let $page = $searchForm.find('#currentPage');

	if(page > 0) {
		$page.val(page - 1);
		search();
	}
}

function setPage() {
	let $searchForm = $('#cpdSearchForm');
	let $pageInput = $searchForm.find('#currentPage');
	let currentPage = loadParam('page') - 1;

	$pageInput.val(currentPage);
}